<template>
  <Dialog v-model:visible="show"
          position="top"
          :modal="false"
          :draggable="true"
          :maximizable="true"
          :auto-z-index="true"
          class="p-fluid p-dialog-sidepanel"
          style="z-index: 9999999"
          @hide="onDialogHide">

    <template #header>
      <TimelineHeader :viaggio="viaggio" :contratto="contratto"/>
    </template>


    <!-- content -->
    <div class="p-fluid grid grid-nogutter mt-1">
      <div class="col-3 md-3 lg-2 xl-2">
        <CommunicationPanel/>
        <TasksPanel :tasks="tasks" @filter="filterTasks"/>
      </div>

      <TimelineStatus :workflow="filteredWorkflow"/>

    </div>

    <ScrollTop target="parent" :threshold="600" class="custom-scrolltop" icon="pi pi-arrow-up"/>
  </Dialog>


</template>

<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import EventService from "@/services/EventService";
import {SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG} from "@/components/common/constants/Events";
import TimelineHeader from "@/components/common/timeline/TimelineHeader";
import TasksPanel from "@/components/common/timeline/TasksPanel";
import TimelineStatus from "@/components/common/timeline/TimelineStatus";
import CommunicationPanel from "@/components/common/timeline/CommunicationPanel";

// dialog
const show = ref(false);
const contratto = ref({});
const viaggio = ref({});

// workflow
const workflow = ref([]);
const filteredWorkflow = ref([]);

const tasks = ref({});

onMounted(() => {
  EventService.on(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, onShow);
})

onUnmounted(() => {
  EventService.off(SHOW_WORKFLOW_TIMELINE_DIAGRAM_DIALOG, onShow);
})

/**
 * When event show arrives
 * @param data
 */
const onShow = async (data) => {
  show.value = true;

  contratto.value = data.contratto;
  viaggio.value = data.viaggio;
  workflow.value = data.workflow;

  filteredWorkflow.value = workflow.value;

  workflow.value.forEach((status) => {

    // Populate tasks
    const actions = status.actions;
    if (null != actions) {
      actions.forEach((a) => {
        if (!a.enabled) {
          tasks.value[a.action] = false;
        }
      });
    }
  });

}

const onDialogHide = () => {
  tasks.value = {};
  filteredWorkflow.value = [];
  workflow.value = [];
}

const filterTasks = (option) => {
  let mapping = [];
  mapping['all'] = workflow.value;
  mapping['completed'] = workflow.value.filter(w => w.enabled);
  mapping['todo'] = workflow.value.filter(w => !w.enabled);
  filteredWorkflow.value = mapping[option];
}


</script>


