<template>
  <div class="mt-1">
    <Button
        :label="t('label.chat')"
        icon="pi pi-comment"
        class="p-button-info"
        @click="showChat"/>
  </div>

  <div class="mt-2">
    <Button
        :label="t('label.mail')"
        icon="pi pi-envelope"
        class="p-button-help"
        @click="showMail"/>
  </div>
</template>

<script setup>
import EventService from "@/services/EventService";
import {SHOW_CHAT, SHOW_MAIL} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";


    const {t} = useI18n();

    const showChat = () => {
      EventService.emit(SHOW_CHAT, {});
    }

    const showMail = () => {
      EventService.emit(SHOW_MAIL, {});
    }

</script>

<style scoped>

</style>
