<template>
  <Panel header="Task checklist" :toggleable="true" class="mt-5">

    <!-- options -->
    <div>
      <div class="p-formgroup-inline no-gutter">

        <!-- all -->
        <div class="field-radiobutton" style="margin-right: 3px">
          <RadioButton id="all" name="all" value="all" v-model="taskOptions" @change="filterTasks"/>
          <label for="all">{{ t('label.viaggio.timeline.task.all') }}</label>
        </div>

        <!-- done -->
        <div class="field-radiobutton" style="margin-right: 3px">
          <RadioButton id="completed" name="completed" value="completed" v-model="taskOptions" @change="filterTasks"/>
          <label for="completed">{{ t('label.viaggio.timeline.task.done') }}</label>
        </div>

        <!-- todo -->
        <div class="field-radiobutton" style="margin-right: 3px">
          <RadioButton id="todo" name="todo" value="todo" v-model="taskOptions" @change="filterTasks"/>
          <label for="todo">{{ t('label.viaggio.timeline.task.todo') }}</label>
        </div>
      </div>
    </div>

    <!-- list -->
    <div v-for="(value, name) of tasks" :key="name" class="field-checkbox mt-2">
      <BooleanIndicator :id="name" :name="name" :status="value" falseTooltip=""/>
      <label :for="name">{{ name }}</label>
    </div>

  </Panel>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {ref} from "vue";
import BooleanIndicator from "@/components/common/BooleanIndicator";

defineProps({tasks: {required: true}});
const emit = defineEmits(['filter']);

const {t} = useI18n();
const taskOptions = ref('all');

const filterTasks = () => {
  emit('filter', taskOptions.value);
}

</script>

<style scoped>

</style>
