<template>
  <span class="ml-2" @click="showChat">
    <i class="pi pi-comment chat"/>
  </span>

</template>

<script setup>
import EventService from "@/services/EventService";
import {SHOW_CHAT} from "@/components/common/constants/Events";

const showChat = () => {
  EventService.emit(SHOW_CHAT, {});
}

</script>

<style scoped>

.chat {
  font-size: 16px;
  font-weight: 1000;
}


</style>
