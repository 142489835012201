<template>
  <div class="actions"
       v-bind:class="{'left': !(0 === index % 2) && value.enabled,
                              'right': (0 === index % 2) || ((!(0 === index % 2)) && !value.enabled),
                              'disabled': !value.enabled,
                              'enabled': value.enabled}">

    <div class="mt-2" v-for="action in value.actions" v-bind:key="action.description">
      <div class="action" v-bind:class="{'disabled': !action.enabled, 'enabled': action.enabled}">

        <!-- icon -->
        <i class="pi pi-circle-off">
          <span class="ml-1 description"> {{ action.action }}</span>
        </i>

        <!-- description -->
        <div class="ml-3 mt-1" v-if="action.description">
          <i class="pi pi-tags">
            <span class="ml-1"> {{ action.description }}</span></i>
        </div>

        <!-- user -->
        <div class="ml-3 mt-1" v-if="action.user">
          <i class="pi pi-user"><span class="p-ml-1"> {{ action.user }}</span></i>
          <span class="ml-2" v-if="!(currentUsername === action.user)">
                    <ChatIcon/>
                    <MailIcon/>
                  </span>
        </div>

        <!-- datetime -->
        <div class="ml-3 mt-1">
          <i class="pi pi-clock" v-if="action.dateTime">
            <span class="ml-1"> {{ dateTimeFormat(action.dateTime) }}</span>
          </i>
        </div>

        <!-- note -->
        <div class="ml-3 mt-1" v-if="action.note">
          <i class="pi pi-circle-off"><span class="p-ml-1"> {{ action.note }}</span></i>
        </div>

        <!-- download -->
        <div class="ml-3 mt-1" v-if="action.url">
          <a :href="action.url">
            <i class="pi pi-download">
              <span class="ml-1">{{ t('action.download') }}</span></i> <span
              class="ml-3"></span>
          </a>
        </div>

      </div>

    </div>
  </div>
</template>

<script setup>
import MailIcon from "@/components/common/timeline/MailIcon";
import ChatIcon from "@/components/common/timeline/ChatIcon";
import {useStore} from "vuex";
import useDateTime from "@/composable/useDateTime";
import {useI18n} from "vue-i18n";

defineProps({
  index: {required: true},
  value: {required: true}
});

const store = useStore();
const {dateTimeFormat} = useDateTime();
const {t} = useI18n();

const currentUsername = store.getters['auth/username'];


</script>

<style lang="scss" scoped>
@import "./colors.scss";

.actions {

  &.left {
    position: relative;
    left: 10%;
    width: 300px;

    &.disabled {
      & .description {
        color: $button_disabled_bg;
      }

      & .pi-circle-off {
        color: $button_disabled_bg;
      }
    }

  }

  &.right {
    position: relative;
    left: 55%;
    width: 300px;

    &.disabled {
      & .description {
        color: $button_disabled_bg;
      }

      & .pi-circle-off {
        color: $button_disabled_bg;
      }
    }
  }

}

.action {
  &.disabled {
    color: $button_disabled_bg;
  }
}


</style>
