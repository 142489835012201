<template>
  <Button :label="t('action.save-confirm')"
          icon="pi pi-check-circle"
          class="p-button p-button-success p-float-right"
          @click="saveCodiceAndConfirmContract"/>
</template>

<script setup>
import contrattoRestService from "@/services/ContrattoRestService";
import eventService from "@/services/EventService";
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

const props = defineProps({contratto: {required: true}})

const {t} = useI18n();
const visible = ref(false);

onMounted(() => {
  checkVisibility();
})

async function saveCodiceAndConfirmContract() {
  await contrattoRestService.confirm(props.contratto);
  eventService.emit("contratto-validate");
}

const checkVisibility = async () => {
  visible.value = await contrattoRestService.isConfirmable(props.contratto);
}
</script>

<style scoped>

</style>
