<template>
 <span class="ml-2" @click="showMailComposer">
    <i class="pi pi-envelope mail"/>
  </span>
</template>

<script setup>

import EventService from "@/services/EventService";
import {SHOW_MAIL} from "@/components/common/constants/Events";



    const showMailComposer = () => {
      EventService.emit(SHOW_MAIL, {});
    }

</script>

<style scoped>

.mail {
  font-size: 16px;
  font-weight: 1000;
}

</style>
