<template>
  <div class="dialog-header" style="text-align: center; width: 100%; margin-left: 160px">

    <!-- contract -->
    <span class="mr-2">{{ t('label.contratto.brokerage') }}</span>
    <span class="header-link" @click="showContrattoData">{{ contratto.codice }}</span>

    <!-- separator -->
    <span v-if="viaggio">
      <span class="ml-2 mr-2"> - </span>
    </span>

    <!-- voyage -->
    <span v-if="viaggio">
      <span class="mr-2">{{ t('label.viaggio') }}</span>
      <span class="header-link" @click="showViaggioData">{{ viaggio.codice }}</span>
    </span>

  </div>
</template>

<script setup>
import EventService from "@/services/EventService";
import {
  SHOW_CONTRATTO_DATA_DIALOG,
  SHOW_VIAGGIO_DATA_DIALOG,
  UPDATE_ACTION
} from "@/components/common/constants/Events";
import {useI18n} from "vue-i18n";


const props = defineProps({viaggio: {}, contratto: {}});

const {t} = useI18n();


const showContrattoData = () => {
  EventService.emit(SHOW_CONTRATTO_DATA_DIALOG, {mode: UPDATE_ACTION, item: props.contratto});
}

const showViaggioData = () => {
  EventService.emit(SHOW_VIAGGIO_DATA_DIALOG, {mode: UPDATE_ACTION, item: props.viaggio});
}

</script>

<style scoped>
.dialog-header {
  font-size: 20px
}

.header-link {
  cursor: pointer;
  font-weight: 500;
}

.header-link:hover {
  text-decoration: underline;
}
</style>
