<template>
  <div class="col timeline" v-if="workflow.length > 0">

        <span v-for="(value, index) in workflow" :key="index">

          <!-- status -->
          <div class="container"
               v-bind:class="{'left': !(0 === index % 2) && value.enabled,
                              'right': (0 === index % 2) || ((!(0 === index % 2)) && !value.enabled),
                              'disabled': !value.enabled,
                              'enabled': value.enabled,
                              'current': value.current }">

            <!-- date -->
            <div class="date" v-if="value.dateTime">{{ d(value.dateTime, 'medium', 'en') }}</div>

            <!-- status icon -->
            <i class="icon pi"
               v-bind:class="{'pi-check enabled': value.enabled, 'pi-times disabled': !value.enabled}"></i>

            <div class="content">

              <!-- status -->
              <h2 class="status">{{ value.status }}</h2>

              <!-- user -->
              <div class="mt-2" v-if="value.user">
                <i class="pi pi-user"><span class="ml-1">{{ value.user }}</span></i>
                 <span class="ml-2" v-if="!(value.user === currentUsername)">
                      <ChatIcon/>
                      <MailIcon/>
                    </span>
              </div>

              <!-- datetime -->
              <div class="mt-2" v-if="value.dateTime">
                <i class="pi pi-clock"><span class="ml-1">{{ dateTimeFormat(value.dateTime) }}</span></i>
              </div>
            </div>


        </div>

          <TimelineActions :index="index" :value="value"/>

        </span>

  </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import ChatIcon from "@/components/common/timeline/ChatIcon";
import MailIcon from "@/components/common/timeline/MailIcon";
import TimelineActions from "@/components/common/timeline/TimelineActions";
import useDateTime from "@/composable/useDateTime";
import {useStore} from "vuex";
import {computed} from "vue";

defineProps({workflow: {required: true}});

const {d} = useI18n();
const {dateTimeFormat} = useDateTime();
const store = useStore();

const currentUsername = computed(() => {
  return store.getters['auth/username'];
});

</script>

<style lang="scss" scoped>
@import "./colors.scss";


*,
*::before,
*::after {
  box-sizing: border-box;
}

// timeline
.timeline {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    background: $secondary;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -1px;
  }
}


// container


.container {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;


  &::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 1px;
    top: calc(50% - 1px);
    right: 8px;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    right: -8px;

    border-radius: 16px;
    z-index: 1;
  }

  &.left {
    left: 0;

    & .icon {
      right: 56px;
    }

    & .date {
      right: -125px;
    }
  }

  &.right {
    left: 50%;

    & .content {
      padding: 20px 30px 20px 90px;
      border-radius: 500px 0 0 500px;
    }

    & .icon {
      left: 56px;
    }

    & .date {
      left: -125px;
    }

    &::before {
      left: 8px;
    }

    &::after {
      left: -8px;
    }
  }

  & > .content {
    padding: 20px 90px 20px 30px;
    background: #fff7dc;
    position: relative;
    border-radius: 0 500px 500px 0;
    border: solid 1px #ded09f;

    h2 {
      margin: 0 0 10px 0;
      font-size: 18px;
      font-weight: normal;
      color: $secondary;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
    }

    & > .status {
      text-transform: uppercase;
    }


  }


  & > .icon {
    position: absolute;
    display: inline-block;
    width: 40px;
    height: 40px;
    padding: 9px 0;
    top: calc(50% - 20px);
    border-radius: 40px;
    text-align: center;
    font-size: 18px;
    z-index: 1;
  }

  & .date {
    position: absolute;
    display: inline-block;
    top: calc(50% - 8px);
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: $secondary;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: 1;
  }


  &.disabled {

    &::before {
      background: $button_disabled_bg;
    }

    &::after {
      background: #ffffff;
      border: 2px solid $button_disabled_bg;
    }

    & > .icon {
      background: #fefefe !important;
      border: 2px solid $button_disabled_bg;
      color: $button_disabled_bg;
    }

    & > .content {
      border: solid 1px $button_disabled_bg;
      background: #fefefe;

      & .status {
        color: $button_disabled_bg;
      }
    }

  }

  &.enabled {
    &::before {
      background: $secondary;
    }

    &::after {
      background: #ffffff;
      border: 2px solid $secondary;

    }

    & > .icon {
      background: $button_enabled_bg !important;
      border: 2px solid $secondary;
      color: $secondary;
    }

    &.current {
      &::after {
        background: #bfeab5;
        border: 2px solid $secondary;
      }

      & > .icon {
        background: #bfeab5 !important;
        border: 2px solid $secondary;
        color: $secondary;
      }
    }

  }


}


@media (max-width: 767.98px) {
  .timeline::after {
    left: 90px;
  }

  .container {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .container.right {
    left: 0%;
  }

  .container.left::after,
  .container.right::after {
    left: 82px;
  }

  .container.left::before,
  .container.right::before {
    left: 100px;
    border-color: transparent $secondary transparent transparent;
  }

  .container.left .date,
  .container.right .date {
    right: auto;
    left: 15px;
  }

  .container.left .icon,
  .container.right .icon {
    right: auto;
    left: 146px;
  }

  .container.left .content,
  .container.right .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}
</style>
