<template>
  <ModalDialog ref="modalDialog"
               v-model:visible="show"
               :draggable="true"
               :modal="false"
               :header="dialogTitle()"
               @hide="onHide">

    <ContrattoData :contratto="item"/>

    <!-- actions -->
    <template #footer>
      <span class="flex justify-content-between flex-wrap">
      <Button :label="t('action.cancel')" icon="pi pi-times" class="p-button p-button-light" @click="hideAction"/>

        <span class="flex p-text-right">
            <Button :label="t('action.salva-bozza')" icon="pi pi-save" class="p-button"
              @click="saveItemAction" v-permission="[Roles.ADMIN, Roles.BROKER]"/>

            <!-- save buyer code and confirm contract -->
            <ConfermaAndSaveContrattoButton :contratto="item" v-permission="[Roles.BUYER]"/>

            <!-- save buyer code -->
            <Button :label="t('action.salva-bozza')" icon="pi pi-save" class="p-button"
              @click="saveCodiceBuyer" v-permission="[Roles.BUYER]"/>

            <!-- save seller code and confirm contract -->
            <ConfermaAndSaveContrattoButton :contratto="item" v-permission="[Roles.SELLER]"/>

            <!-- save seller code -->
            <Button :label="t('action.salva-bozza')" icon="pi pi-save" class="p-button"
              @click="saveCodiceSeller" v-permission="[Roles.SELLER]"/>

            <!-- save forwarding code -->
            <Button :label="t('action.salva-bozza')" icon="pi pi-save" class="p-button p-button-success"
              @click="saveCodiceForwarding" v-permission="[Roles.SPEDIZIONIERE]"/>

        </span>

      </span>
    </template>
  </ModalDialog>
</template>

<script setup>
import {ref, provide, onMounted, onUnmounted} from "vue";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import ContrattoData from "@/components/contratto/ContrattoData";
import eventService from "@/services/EventService";
import ModalDialog from "@/components/common/ModalDialog";
import Roles from "@/components/common/constants/Roles";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import ConfermaAndSaveContrattoButton from "@/components/contratto/bottoni/ConfermaAndSaveContrattoButton";
import {SHOW_CONTRATTO_DATA_DIALOG} from "@/components/common/constants/Events";

const {t} = useI18n();
const store = useStore();

let show = ref(false);
let item = ref({});
let mode = ref("");

const modalDialog = ref();

const rules = {
  codice: {
    required: helpers.withMessage(t("validation.required", {field: t("label.codice")}), required),
  },
};
const v$ = useVuelidate(rules, item);


const showContrattoDialogAction = (data) => {
  show.value = true;
  item.value = data.item;
  mode.value = data.mode;
}

const contrattoValidAction = (contratto) => {
  const isBroker = store.getters['auth/isBroker'];
  const isBuyer = store.getters['auth/isBuyer'];
  const isSeller = store.getters['auth/isSeller'];
  const isSpedizioniere = store.getters['auth/isSpedizioniere'];

  if (isBroker) {
    const fullAction = "contratto/" + mode.value;
    item.value = contratto;
    item.value.workflowId = contratto.workflow.id;
    store.dispatch(fullAction, item.value);

  } else if (isBuyer) {
    store.dispatch("contratto/updateCodiceBuyer", contratto);

  } else if (isSeller) {
    store.dispatch("contratto/updateCodiceSeller", contratto);

  } else if (isSpedizioniere) {
    store.dispatch("contratto/updateCodiceContrattoForwarding", contratto);
  }

  hideAction();

}

onMounted(() => {
  eventService.on(SHOW_CONTRATTO_DATA_DIALOG, showContrattoDialogAction);
  eventService.on("contratto-valid", contrattoValidAction);

});

onUnmounted(() => {
  eventService.off(SHOW_CONTRATTO_DATA_DIALOG, showContrattoDialogAction);
  eventService.off("contratto-valid", contrattoValidAction);
});

const onHide = () => {
  v$.value.$reset();
};

const hideAction = () => {
  show.value = false;
};

const saveItemAction = () => {
  eventService.emit("contratto-validate");
};

const saveCodiceBuyer = () => {
  eventService.emit("contratto-validate");
}

const saveCodiceSeller = () => {
  eventService.emit("contratto-validate");
}

const saveCodiceForwarding = () => {
  eventService.emit("contratto-validate");
}

const dialogTitle = () => {
  return mode.value === "insert" ? t("label.contratto.titolo.insert") : t("label.contratto.titolo.edit");
};

provide("v$", v$);

</script>

<style scoped></style>
